<template>
       <div class="main">
        
            <div class="header-container">
                <div class="logo-container">
                    <img src="./../assets/images/backbone-logo.png" alt="logo BACKBONE" />
                </div>
                <div class="logo-sep"></div>
                <div class="title-container">
                    <h1>Webinar</h1>
                </div>
            </div>
            <template v-if="!error">
                <h1 class="text-subtitle">Password Reset</h1>
                <div class="sep-subtitle"></div>
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(resetPwd)" >
                        <ValidationProvider  class="input-login"  rules="required|max:32|min:8" vid="pwd" v-slot="{ errors }">
                            <label>New password<span class="input-required">*</span></label>
                            <input type="password" :class="{ 'input-error' : errors[0]}"   v-model="pwd" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider  class="input-login"  rules="required|max:32|min:8|confirmedPwd:pwd" v-slot="{ errors }">
                            <label>New password confirmation <span class="input-required">*</span></label>
                            <input type="password" :class="{ 'input-error' : errors[0]}"   v-model="pwdConfirm" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <input type="submit" class="btn-form" value="RESET" />
                        <div class="form-failed" v-if="errorMsg" v-html="errorMsg"></div>
                        <div class="form-succes" v-if="success">Your password has been changed.<br />Please <router-link to="/login">log in</router-link></div>
                    </form>
                </ValidationObserver>
            </template>
            <template v-else>
                <div class="error" v-if="error" >
                    <img src="./../assets/images/icon-error.png" alt="error" />
                    <p v-html="error"></p>
                </div>
            </template>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import axios from 'axios'
import store from './../store'

export default {
    name :'PasswordReset',
    components : {
        ValidationProvider, ValidationObserver
    },
    data() {
        return {
            pwd : null,
            pwdConfirm : null,
            errorMsg : null,
            success : false,
            error: null
        }
    },
    methods : {
        resetPwd() {
            this.errorMsg = null
            this.success = false

            let vm = this
            let params = {
                pwd : this.pwd,
                pwdConfirm : this.pwdConfirm,
                id : this.$route.params.id,
                key : this.$route.params.key
            }
            axios
                .post(store.getters.getURL + "user/passwordReset.php", params )
                .then(function (response) { 
                    if(response.data.state == 'success') {
                        vm.success = true
                    }else {
                        vm.errorMsg = response.data.retour
                    }
                })
                .catch((err) => {
                    this.errorMsg = "An error has occured: " +  err
                });

        }
    },
    mounted() {

        let vm = this

        axios
            .get(store.getters.getURL + "user/verifyPassword.php?id=" + this.$route.params.id + "&key=" + this.$route.params.key )
            .then(function (response) { 
                if(response.data.state != 'success') {
                    vm.error = response.data.retour
                }
            })
            .catch((err) => {
                this.error = "An error has occured: " +  err
            });
    }
}
</script>

<style lang="scss" scoped>
    .form-succes a {
        color:$succes;
        text-decoration: none;
    }

    .form-succes a:hover {
        text-decoration: underline;
    }
</style>